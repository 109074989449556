import { FC } from "react";

import CustomerPanel from "@components/CustomerPanel";
import { UserConsumer } from "@components/UserContext";

const SecondaryNavigation: FC = () => {
  return (
    <>
      <UserConsumer>
        {(consumer) => (
          <ul className="SecondaryNavigation">
            {consumer?.customer ? (
              <li>
                {consumer?.customer.guest ? (
                  <a href="/login">Sign in / Create</a>
                ) : (
                  <CustomerPanel customer={consumer?.customer} />
                )}
              </li>
            ) : null}
          </ul>
        )}
      </UserConsumer>
      {/* language=CSS */}
      <style jsx>
        {`
          .SecondaryNavigation {
            display: inline-block;
            margin: 0 0 20px 0;
            list-style-type: none;
            height: 18px;
          }

          .SecondaryNavigation li {
            display: inline-block;
          }

          a {
            color: rgb(51, 51, 51);
            text-decoration: none;
            font-size: 12px;
          }

          a:hover {
            color: rgb(0, 102, 204);
          }
        `}
      </style>
    </>
  );
};

export default SecondaryNavigation;

import { FC } from "react";

import LargeViewPortNavigation from "@components/LargeViewPortNavigation";
import Logo from "@components/Logo";
import PrimaryNavigation from "@components/PrimaryNavigation";
import SecondaryNavigation from "@components/SecondaryNavigation";
import SmallViewPortNavigation from "@components/SmallViewPortNavigation";

const Header: FC = () => {
  return (
    <>
      <div className="header">
        <div className="column-1">
          <Logo />
        </div>
        <div className="column-2">
          <LargeViewPortNavigation />
          <SmallViewPortNavigation />
        </div>
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .header {
            display: flex;
            align-items: flex-end;
          }

          .column-1 {
            flex: 0;
          }

          .column-2 {
            flex: 1;
            display: flex;
            justify-content: flex-end;
          }
        `}
      </style>
    </>
  );
};

export default Header;

import { FC } from "react";

export enum IconType {
  TwitterSquare = "\\f081",
  FacebookSquare = "\\f082",
  LinkedIn = "\\f08c",
}

export enum IconColor {
  Primary = "rgb(0, 102, 204)",
  Secondary = "rgb(51, 51, 51)",
  White = "#ffffff",
}

const FaBrandIcon: FC<{ type: IconType; color?: IconColor; size?: string }> = ({
  type,
  color = IconColor.Primary,
  size,
}) => {
  return (
    <>
      <span className="FaIcon" />
      {/* language=CSS */}
      <style jsx>
        {`
          .FaIcon {
            font-family: "Font Awesome 5 Brands-Regular-400";
            color: ${color};
            padding-top: 2px;
            line-height: 30px;
            ${size ? `font-size: ${size}` : null}
          }

          .FaIcon::before {
            content: "${type}";
          }
        `}
      </style>
    </>
  );
};

export default FaBrandIcon;

import { FC } from "react";

import Container from "@components/Container";
import FaBrandIcon from "@components/FaBrandIcon";
import { IconColor, IconType } from "@components/FaBrandIcon/FaBrandIcon";

const Footer: FC = () => {
  return (
    <>
      <div className="footer">
        <Container>
          <div className="sub-footer">
            <div className="sub-section-1" />
            <div className="sub-section-2">
              <div className="links-grid">
                <a className="privacy" href="/privacy">
                  Privacy Policy
                </a>
                <span>|</span>
                <a className="refund" href="/refund-policy">
                  Refund Policy
                </a>
              </div>
              <div>
                <span>© 2022 Terex Corporation. Terex, Genie</span>
              </div>
              <div>
                <span>
                  Powered by{" "}
                  <a href="https://www.kencook.com/" target="_blank">
                    Ken Cook Co.
                  </a>
                </span>
              </div>
            </div>
            <div className="sub-section-3">
              <span>Follow Us</span>
              <div className="footer-social">
                <a href="https://twitter.com/genielift" target="_blank">
                  <FaBrandIcon type={IconType.TwitterSquare} color={IconColor.White} size="2em" />
                </a>
                <a href="https://www.facebook.com/GenieLift" target="_blank">
                  <FaBrandIcon type={IconType.FacebookSquare} color={IconColor.White} size="2em" />
                </a>
                <a href="https://www.linkedin.com/company/genie-industries" target="_blank">
                  <FaBrandIcon type={IconType.LinkedIn} color={IconColor.White} size="2em" />
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* language=CSS */}
      <style jsx>
        {`
          .footer {
            background-color: rgb(0, 102, 204);
            color: #ffffff;
            text-align: center;
            padding: 10px 0;
          }

          .footer a {
            color: #ffffff;
            text-decoration: none;
          }

          .footer a:hover {
            color: #275d8b;
            text-decoration: underline;
          }

          .links-grid {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            grid-column-gap: 5px;
          }

          .privacy {
            text-align: right;
          }

          .refund {
            text-align: left;
          }
          .sub-footer {
            margin: 20px 0;
            display: flex;
            line-height: 1.5;
            font-size: 11px;
          }

          .sub-section-1 {
            flex: 1;
            text-align: left;
          }

          .sub-section-2 {
            flex: 1;
            text-align: center;
          }

          .sub-section-3 {
            flex: 1;
            text-align: right;
          }

          .footer-social {
            text-align: right;
          }

          .footer-social a {
            margin-left: 10px;
          }
        `}
      </style>
    </>
  );
};

export default Footer;
